import {
  UilApps,
  UilCalendarAlt,
  UilFilter,
  UilMoneyStack,
  UilQuestionCircle,
  UilSetting,
  UilSignout,
  UilUserCircle,
  UilUserPlus,
} from '@iconscout/react-unicons';
import Image from 'next/image';
import Link from 'next/link';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import logo from '@/assets/home/logo.png';
import SetupIncomplete from '@/components/Molecule/Home/SetupIncomplete';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { useAuth } from '@/hooks/useAuth';
import { InternalLink } from '@/hooks/useInternalRouter';

function Header() {
  const { me } = useAuth();

  if (!me) {
    return (
      <div className="flex flex-col gap-32 px-24 pt-16">
        <InternalLink href="/" className="contents">
          <div className="h-26">
            <Image src={logo} width={105} height={26} alt="logo" />
          </div>
        </InternalLink>
        <div className="flex w-full flex-col gap-8">
          <span className="header-3-b text-gray-25">
            Welcome to Artist page
          </span>
          <span className="body-2-r text-gray-50">
            Please log in to get started booking as an artist.
          </span>
        </div>
        <div className="flex w-full flex-col gap-16">
          <InternalLink href="/signin" className="contents">
            <Button variant="primary" size="lg">
              Log in
            </Button>
          </InternalLink>
          <InternalLink href="/signup" className="contents">
            <Button variant="secondary" size="lg">
              Sign up
            </Button>
          </InternalLink>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-32 px-24 pt-16">
      <InternalLink href="/" className="contents">
        <div className="h-26">
          <Image src={logo} width={105} height={26} alt="logo" />
        </div>
      </InternalLink>
      <div className="flex flex-col gap-16">
        <div className="flex items-center gap-12">
          <div className="flex-center size-32 shrink-0 rounded-full bg-gray-25">
            <span className="header-5-m capitalize text-gray-500">
              {me.username[0]}
            </span>
          </div>
          <span className="label-small capitalize text-gray-25">
            {me.username}
          </span>
        </div>
        {!me.isStripeSetupComplete && (
          <InternalLink href="/settings">
            <SetupIncomplete />
          </InternalLink>
        )}
      </div>
    </div>
  );
}

function Drawer(props: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
}) {
  const { children, isOpen, setIsOpen } = props;

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent side="left">
        <div className="relative size-full overflow-y-scroll">
          <div className="" />
          <Header />
          <div className="h-32 w-full" />
          <div className="h-1 w-full bg-gray-500" />
          <div className="h-22 w-full" />
          <div className="flex flex-col gap-20 px-24 pb-50">{children}</div>
        </div>
      </SheetContent>
    </Sheet>
  );
}

function Item(props: { children?: ReactNode }) {
  const { children } = props;

  return <div className="label-small text-gray-25">{children}</div>;
}

function Content() {
  const { me, logout } = useAuth();
  const artists = me?.artists;

  if (!me) {
    return (
      <Drawer.Item>
        <Link href="https://www.gigfinesse.com/">
          <div className="flex items-center gap-10 py-10">
            <UilQuestionCircle className="size-20 fill-gray-25" />
            <span className="label-small">Learn More</span>
          </div>
        </Link>
      </Drawer.Item>
    );
  }

  if (me) {
    return (
      <>
        {me.isDoorPerson && (
          <Drawer.Item>
            <InternalLink href="/door/calendar">
              <div className="flex items-center gap-10 py-10">
                <UilCalendarAlt className="size-20 fill-gray-25" />
                <span className="label-small">Door</span>
              </div>
            </InternalLink>
          </Drawer.Item>
        )}
        {(artists?.length ?? 0) > 0 ? (
          <>
            <Drawer.Item>
              <InternalLink href="/slots">
                <div className="flex items-center gap-10 py-10">
                  <UilApps className="size-20 fill-gray-25" />
                  <span className="label-small">My Shows</span>
                </div>
              </InternalLink>
            </Drawer.Item>
            <Drawer.Item>
              <InternalLink href="/history">
                <div className="flex items-center gap-10 py-10">
                  <UilMoneyStack className="size-20 fill-gray-25" />
                  <span className="label-small">Show History</span>
                </div>
              </InternalLink>
            </Drawer.Item>
            <Drawer.Item>
              <InternalLink href="/profile">
                <div className="flex items-center gap-10 py-10">
                  <UilUserCircle className="size-20 fill-gray-25" />
                  <span className="label-small">My Profile</span>
                </div>
              </InternalLink>
            </Drawer.Item>
            <Drawer.Item>
              <InternalLink href="/preference">
                <div className="flex items-center gap-10 py-10">
                  <UilFilter className="size-20 fill-gray-25" />
                  <span className="label-small">Show Preferences</span>
                </div>
              </InternalLink>
            </Drawer.Item>
          </>
        ) : null}

        <Drawer.Item>
          <InternalLink href="/settings">
            <div className="flex items-center gap-10 py-10">
              <UilSetting className="size-20 fill-gray-25" />
              <span className="label-small">Account Settings</span>
            </div>
          </InternalLink>
        </Drawer.Item>
        {me.artists.length > 0 && me.ableToCreateNewArtistProfile && (
          <Drawer.Item>
            <InternalLink href="/profile/add">
              <div className="flex items-center gap-10 py-10">
                <UilUserPlus className="size-20 fill-gray-25" />
                <span className="label-small">Add New Profile</span>
              </div>
            </InternalLink>
          </Drawer.Item>
        )}

        <Drawer.Item>
          <button className="w-full" onClick={logout}>
            <div className="flex items-center gap-10 py-10">
              <UilSignout className="size-20 fill-gray-25" />
              <span className="label-small">Log Out</span>
            </div>
          </button>
        </Drawer.Item>
      </>
    );
  }

  return null;
}

Drawer.Item = Item;
Drawer.Content = Content;

export default Drawer;
